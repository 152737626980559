._3Zbwc {
    background: black;
    flex: 1;
    margin: 0;
    overflow: hidden;
    min-height: 50px;
    padding: 0;
    width: 100%;
    height: 100%;
}

._1MBkJ {
    background: black;
    flex: 1;
    margin: 0;
    overflow: hidden;
    min-height: 30px;
    padding: 0;
    width: 100%;
    height: 30px;
}

._1yysw {
    background: black;
    display: block;
    height: 0px;
    margin: 0;
    padding: 0;
    width: 0px;
}

._26rYC {
    background: black;
    display: block;
    height: calc(100% - 30px);
    margin: 0;
    padding: 0;
    width: 100%;
}

._1-it4 {
    background: #2c333e;
    height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

._1-it4 ._25rEj {
    border: 1px solid grey;
    border-bottom-width: 0px;
    border-top-width: 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 30px;
    transition: background-color 0.5s ease;
    width: 30px;
}

._1-it4 ._25rEj:hover {
    background: #2e5046;
}

._1-it4 ._25rEj svg {
    display: block;
    height: 1em;
    margin: auto;
    text-align: center;
}
._3LXDP {
    display: flex;
    justify-content: space-between;
    width: 680px;
    height: 430px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 15px;
    z-index: 999;
}
._3oX6Y {
    margin-left: 30px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 20px;
}
._zBQdX {
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border: black solid 1px;
    height: 35px;
    width: 150px;
    border-radius: 7px;
    color: white;
}
._zBQdX:hover {
    background-color: rgba(191, 191, 191, 0.5);
}
._2VNf4 {
    width: 40px;
    height: 40px;
    margin-left: 300px;
    background-color: rgba(0, 0, 0, 0);
    color: grey;
    border: 0px;
    font-size: 30px;
}
._2VNf4:hover {
    color: white;
}

._1aqAY {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
}
._1-it4 ._25rEj span {
    margin: auto;
}

._25rEj button {
    background: #2c333e;
    border: 0;
    outline: none;
    background-color: transparent;
    margin: auto;
}

._1-it4 ._Tf5yg {
    left: 0;
    position: absolute;
}

._1-it4 ._3-glk {
    position: absolute;
    right: 60px;
    width: 30px;
}
._1-it4 ._1ImUE {
    position: absolute;
    right: 90px;
    width: 30px;
}
._1-it4 ._hUQUb {
    position: absolute;
    right: 120px;
    width: 30px;
}

._1-it4 ._2sF6f {
    position: absolute;
    right: 30px;
    width: 30px;
}

._1-it4 ._96v4w {
    position: absolute;
    right: 0;
}

._1-it4 ._3y8ur {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    left: 45px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 7px;
    white-space: nowrap;
    width: calc(100% - 230px);
}

._1-it4 ._3y8ur ._2580h {
    color: #55b5e4;
    margin-right: 10px;
}

._1-it4 ._3y8ur ._13lOn {
    color: #fff;
}

._3sVga {
    height: 25px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

._MuDap {
    border: 1px solid #787878;
    color: #000;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 3;
}

._BfY6X {
    z-index: 2000;
}

._3_QxJ {
    border-top: 1px solid #787878;
    height: 25px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

._1dzE9 {
    border-width: 0;
    border-right: 1px solid #787878;
    display: none;
    position: absolute;
    top: 0;
}

._1Eie8 {
    color: #ddd;
    display: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    padding-left: 5px;
    position: absolute;
    top: 12px;
}

._35Pdt {
    background: #4a4a4a;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}
._1PPtY {
    background: #4a4a4a;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: 10px;
}

/*.dragLine {
    height: 9px;
    position: fixed;
    width: 100%;
}

.dragLine p {
    border-bottom: 1px solid white;
    cursor: grab;
    display: block;
    margin: 0 auto;
    width: 30px;
}*/

._1uYMH {
    align-items: center;
    background: blue;
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    font-size: 11px;
    height: 15px;
    justify-content: left;
    line-height: 11px;
    overflow: hidden;
    padding-left: 3px;
    position: absolute;
    text-transform: uppercase;
    white-space: nowrap;
    word-break: break-all;
}
._2Da1T {
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    height: 15px;
    width: 100%;
}
._aHcJI {
    display: block;
    pointer-events: none;
    position: fixed;
    z-index: 2000;
}
._18P3J {
    width: 100px;
}
._lFLN9 {
    height: 15px;
    position: relative;
    width: 100%;
}

._3XIUk {
    background: #88255f;
    height: 100%;
    left: 0;
    position: absolute;
    width: 2px;
    z-index: 1001;
}

._231K0 {
    background: #000;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    width: 2px;
    z-index: 2;
}

._36txJ {
    background: #5cb85c88;
    height: 100%;
    left: 0;
    position: absolute;
    z-index: 1000;
}

._2R4Hi {
    background: #99cc00;
    height: 100%;
    left: 0;
    position: absolute;
    width: 3px;
    z-index: 1001;
}

._3o0Pk {
    background: rgb(255, 0, 127);
    height: 100%;
    left: 0;
    position: absolute;
    width: 3px;
    z-index: 1002;
}

._3Zk3c {
    cursor: ew-resize;
}

._1sgUD {
    margin-bottom: 5px;
    margin-top: 5px;
    min-height: 30px;
    position: relative;
}

._3-FG3 {
    position: absolute;
    z-index: 5000;
}

._6w6l2 {
    background: #2c333e;
    height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border-top: 1px solid grey;
}

._1xECG {
    border: 1px solid grey;
    border-bottom-width: 0px;
    border-top-width: 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 30px;
    transition: background-color 0.5s ease;
    width: 30px;
}

._Bio7K {
    border: 1px solid grey;
    border-bottom-width: 0px;
    border-top-width: 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 30px;
    transition: background-color 0.5s ease;
    width: 40px;
}

._2GOd5 ._1xECG:hover {
    background: #2e5046;
}

._2GOd5 ._Bio7K:hover {
    background: #5cb85c88;
}

._1xECG svg {
    display: block;
    height: 1em;
    margin: auto;
    text-align: center;
}

._Bio7K svg {
    display: block;
    height: 1em;
    margin: auto;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border-left-width: 2px;
    border-left-style: solid;
    border-right-width: 2px;
    border-right-style: solid;
}

._2GOd5 {
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 100%;
}

._2GOd5 ._15hVr {
    display: flex;
    flex-direction: row;
    height: 30px;
    margin: 0;
    width: 94px;
}

._2GOd5 ._3-azQ {
    display: flex;
    flex-direction: row;
    height: 30px;
    margin: 0 0 0 50px;
    width: 83px;
}

._2GOd5 ._lbGqs {
    border-radius: 3px;
    margin-right: 6px;
}

._2GOd5 ._1LdQr {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

._2GOd5 ._2lKeM {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

._2GOd5 ._1DYho {
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 0 10px;
}

._2Xtb1 {
    cursor: pointer;
    height: 20px;
    position: relative;
    width: 210px;
}

._2Xtb1 ._uqJ9Q {
    background-image: linear-gradient(to bottom, #555e6d 0, #606a7a 100%);
    background-repeat: repeat-x;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555e6d',endColorstr='#ff606a7a',GradientType=0);
    height: 12px;
    left: 5px;
    position: absolute;
    top: 4px;
    width: 200px;
    z-index: 1;

    -moz-box-sizing: border-box;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
}
._2Xtb1 ._ownBx {
    background-image: linear-gradient(to bottom, #555e6d 0, #606a7a 100%);
    background-repeat: repeat-x;
    border-radius: 4px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555e6d',endColorstr='#ff606a7a',GradientType=0);
    height: 12px;
    left: 5px;
    position: absolute;
    top: 4px;
    width: 0;
    z-index: 2;

    -moz-box-sizing: border-box;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
}

._2Xtb1 ._pdstO {
    background-color: #337ab7;
    background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
    background-repeat: repeat-x;
    border: 0 solid transparent;
    border-radius: 50%;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: col-resize;
    filter: none;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7',endColorstr='#ff2e6da4',GradientType=0);
    height: 20px;
    left: 0;
    margin-left: -10px;
    position: absolute;
    top: 0;
    width: 20px;
    z-index: 3;

    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
}

._2GOd5 ._1mSaw {
    text-align: center;
    width: 120px;
}

._2GOd5 ._1mSaw ._1xECG {
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    width: 100px;
}

/* add css module styles here (optional) */

._1zk04 {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

._37NAy {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 30px;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
